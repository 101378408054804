
import OrderDataService from '@/services/orderProductService'
import moment from 'moment'
import { useNotification } from '@/composables'
import { IOrderWarehouse } from '@/interfaces/orders-warehouse'
import { defineComponent, ref } from 'vue'
import { Timer } from '@element-plus/icons'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { Timer },

  setup() {
    const router = useRouter()
    const state = OrderDataService.state
    const orderDetail = (_id: string): void => {
      state.isSubmited = OrderDataService.ACTION.UPDATE
      router.push({
        name: 'seller.orders-warehouse.update',
        params: { id: _id },
      })
    }
    return { state, moment, router, orderDetail }
  },
})
