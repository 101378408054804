
import { defineComponent, ref, computed, reactive, watch, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useNotification } from '@/composables'
import FilterTable from '@/components/common/FilterTable.vue'
import List from './components/OrderList.vue'
import { useRouter } from 'vue-router'
import UploadFiles from '@/components/common/UploadFiles.vue'
import { FileItem } from '@/interfaces/orders-warehouse'
import OrderDataService from '@/services/orderProductService'
import moment from 'moment'

import {
  IOrderWarehouse,
  Params,
  IServiceResponse,
  ICountOrderByStatus,
} from '@/interfaces/orders-warehouse'
import Pagination from '@/components/common/Pagination.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    FilterTable,
    List,
    UploadFiles,
    Pagination,
  },
  setup() {
    const state = OrderDataService.state
    const router = useRouter()
    const breadcrumbs = ['Orders', '']
    const titleCreate = 'Create Order'
    const titleExport = 'Export Order'
    const titleImport = 'Import Order'
    const { success, error } = useNotification()
    //let isShowImportModal = true
    const fulfillValue = ref()
    const fulfillmentStatus = [
      { label: 'Fulfilled', value: 'Fulfilled' },
      { label: 'Unfulfilled', value: 'Unfulfilled' },
      { label: 'In Review', value: 'InReview' },
      { label: 'Approved', value: 'Approved' },
      { label: 'Rejected', value: 'Rejected' },
      { label: 'Cancel', value: 'Cancel' },
      { label: 'Partially Fulfilled', value: 'Partially Fulfilled' },
      { label: 'Hold', value: 'Hold' },
    ]
    const sourceValue = ref()
    const sourceStatus = [
      { label: 'All', value: '' },
      { label: 'Manual', value: 'manual' },
      { label: 'Import', value: 'import' },
    ]
    const fileList = ref<FileItem[]>([]) // File import
    let isShowModalImport = ref<boolean>(false) //Hiển thị popup import
    const totalOrder = ref() //Tổng số order để phân trang
    const dateFilter = ref() //filter order theo date
    const countOrderByStatus = ref<ICountOrderByStatus>()
    const getOrders = async (): Promise<void> => {
      const orders: IServiceResponse = await OrderDataService.getAll(
        state.params
      )
      state.orders = orders.data.data as IOrderWarehouse[]
      totalOrder.value = orders.data?.pagination?.total
      state.isLoading = false
    }
    const getCountOrderByStatus = async (): Promise<void> => {
      try {
        const res = await OrderDataService.countOrderByStatus(state.params)
        countOrderByStatus.value =
          res.status === 200
            ? res.data.reduce(
              (o: any, c: any) => ({ ...o, [c._id]: c.count }),
              {}
            )
            : {}
      } catch (e) {
        error('')
      }
    }
    const filterByFulfillStatus = async (data: Array<string>) => {
      state.params.status = data?.join(',')
    }
    const filterBySource = async (data: string) => {
      state.params.source = data
    }
    const onChangeDate = async () => {
      if (dateFilter.value?.length > 1) {
        let startDate = moment(dateFilter.value[0]).format('MM/DD/YYYY')
        let endDate = moment(dateFilter.value[1]).format('MM/DD/YYYY')
        state.params.orderDate = {
          startDate: startDate,
          endDate: endDate,
          timezone: 'GMT+07',
        }
      } else {
        delete state.params.orderDate
      }
    }
    const onChangeFiles = (files: any) => {
      fileList.value = files
    }
    const showModalImport = (): void => {
      isShowModalImport.value = true
    }
    onMounted(() => {
      state.isLoading = true
      getOrders()
      getCountOrderByStatus()
    })
    watch(state.params, () => {
      getOrders()
      getCountOrderByStatus()
    }) //params có bất kỳ thay đổi thì gọi getOrder
    const onSearchOrder = async (data: any): Promise<void> => {
      state.params.search = data?.search
    }
    const onSubmitImport = async (): Promise<void> => {
      try {
        let file = fileList.value[0].raw
        const { status, data } = await OrderDataService.importOrdersFromCSV(
          file
        )
        if (status === 201 || status === 200) {
          success(data.message)
        } else {
          error(data)
        }
        isShowModalImport.value = false
        await getOrders()
      } catch (e) {
        error('')
      }
    }
    return {
      showModalImport,
      content: computed(() => {
        return { breadcrumbs, titleImport, titleExport, titleCreate }
      }),
      isShowModalImport,
      fulfillmentStatus,
      sourceStatus,
      router,
      onChangeFiles,
      onSubmitImport,
      totalOrder,
      state,
      onSearchOrder,
      sourceValue,
      fulfillValue,
      getOrders,
      filterByFulfillStatus,
      filterBySource,
      onChangeDate,
      dateFilter,
      countOrderByStatus,
    }
  },
})
